import React, { useRef, useState } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { MeshStandardMaterial } from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { useTexture } from "@react-three/drei";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";

export function HeadModel(props: any) {
  const objRef = useRef();
  const materials = useLoader(MTLLoader, "head3d.mtl");
  const obj = useLoader(OBJLoader, "dees.obj", (loader) => {
    materials.preload();
    loader.setMaterials(materials);
  });
  useFrame(() => {
    //@ts-ignore
    objRef.current.position.z += 0.005;
    //@ts-ignore
    if (objRef.current.position.z > 5) {
      //@ts-ignore
      objRef.current.position.z = -2;
    }
  });
  return <primitive ref={objRef} object={obj} scale={0.01} />;
}

const diamondMaterial = new MeshStandardMaterial({
  color: "#bf900f",
  // emissive: "",
  roughness: 0.1,
});
