import {
  Environment,
  Html,
  OrbitControls,
  useProgress,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { HeadModel } from "./model";
import { Suspense } from "react";

export const Head = () => {
  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        overflowY: "hidden" // hide vertical
      }}
    >
      <div
        style={{
          position: "relative",
          background: "transparent",
          height: "100%",
          zIndex: "100",
        }}
      >
        <Canvas>
          <Suspense fallback={<Loader />}>
            {/* <OrbitControls /> */}
            <ambientLight intensity={1.5} />
            <HeadModel />
          </Suspense>
        </Canvas>
      </div>
      <div
        style={{
          backgroundColor: "#eed6ff",
          position: "absolute",
          zIndex: "-100",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
        }}
      >
        <h1
          style={{ color: "#cf0eb2", fontFamily: "monospace", marginBottom: "10rem" }}
        >
          More Dees Coming Soon
        </h1>
      </div>
    </div>
  );
};
function Loader() {
  const { progress } = useProgress();
  return <Html center>{progress} % loaded</Html>;
}
